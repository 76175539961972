









































































































































































































































import CenoteDTO, { CenoteIssue, CenoteType } from '@/models/CenoteDTO';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    props: {
        cenote: CenoteDTO,
    },
})
export default class EditCenoteDialog extends Vue {
    dialog = false;
    valid = false;
    editName = false;
    dms = false;

    types = Object.values(CenoteType);
    touristic = [true, false];
    latitudeText = '';
    longitudeText = '';
    latitudeDirSelection = 'N';
    longitudeDirSelection = 'W';
    modeSet = 'DD';

    issues = Object.values(CenoteIssue);
    latitudeDir = ['N', 'S'];
    longitudeDir = ['W', 'E'];
    mode = ['DD', 'DMS'];

    remove(item: string): void {
        this.$props.cenote.alternativeNames.splice(
            this.$props.cenote.alternativeNames.indexOf(item),
            1,
        );
        this.$props.cenote.alternativeNames = [
            ...this.$props.cenote.alternativeNames,
        ];
    }

    toDMSLatitude(latitudeText: number): string {
        var absolute = Math.abs(latitudeText);
        var degrees = Math.floor(absolute);
        var minutesNotTruncated = (absolute - degrees) * 60;
        var minutes = Math.floor(minutesNotTruncated);
        var seconds = Math.floor((minutesNotTruncated - minutes) * 60);

        return degrees + 'º ' + minutes + "' " + seconds + "'' ";
    }

    toDMSLongitude(longitudeText: number): string {
        var absolute = Math.abs(longitudeText);
        var degrees = Math.floor(absolute);
        var minutesNotTruncated = (absolute - degrees) * 60;
        var minutes = Math.floor(minutesNotTruncated);
        var seconds = Math.floor((minutesNotTruncated - minutes) * 60);

        return degrees + 'º ' + minutes + "' " + seconds + "'' ";
    }

    dmsLatitude = this.toDMSLatitude(this.$props.cenote.getLatitude());
    dmsLongitude = this.toDMSLongitude(this.$props.cenote.getLongitude());

    ConvertDMSToDD(
        degrees: number,
        minutes: number,
        seconds: number,
        direction: string,
    ): number {
        var dd = degrees + minutes / 60 + seconds / (60 * 60);

        if (direction == 'S' || direction == 'W') {
            dd = dd * -1;
        } // Don't do anything for N or E
        return dd;
    }

    created(): void {
        // this.cenote = new CenoteDTO(this.$props.cenoteProp);
        let lat = this.$props.cenote.getLatitude();
        let lon = this.$props.cenote.getLongitude();

        if (lat) {
            this.latitudeText = Math.abs(lat).toString();

            if (lat >= 0) this.latitudeDirSelection = 'N';
            else this.latitudeDirSelection = 'S';
        } else this.latitudeText = '';

        if (lon) {
            this.longitudeText = Math.abs(lon).toString();

            if (lon >= 0) this.longitudeDirSelection = 'E';
            else this.longitudeDirSelection = 'W';
        } else this.longitudeText = '';
    }

    async save(): Promise<void> {
        try {
            let lat = JSON.parse(this.latitudeText);
            let lon = JSON.parse(this.longitudeText);
            if (this.latitudeDirSelection === 'S') lat = -lat;
            if (this.longitudeDirSelection === 'W') lon = -lon;
            this.$props.cenote.setCoordinates(lat, lon);

            this.$emit('onSave');
            this.dialog = false;
        } catch (error) {
            await this.$store.dispatch('error', error);
        }
    }
}
