var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._t("activator",null,{"on":on,"attrs":attrs})]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pt-5 mt-5 justify-center"},[_c('v-card-title',[(_vm.editName === false)?_c('span',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.cenote.name))]):_vm._e(),(
                    (!_vm.cenote.name || _vm.cenote.name === '') &&
                    _vm.editName === false
                )?_c('span',{staticClass:"text-h6"},[_vm._v("Name")]):_vm._e(),(_vm.editName === false)?_c('v-icon',{staticClass:"pl-2 pt-2",attrs:{"medium":"","color":"green"},on:{"click":function () {
                        _vm.editName = true;
                    }}},[_vm._v("mdi-pencil ")]):_vm._e(),(_vm.editName === true)?_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{staticClass:"d-flex"},[_c('v-text-field',{attrs:{"label":"Name","rules":[function (v) { return !!v || 'Name is required'; }],"required":""},model:{value:(_vm.cenote.name),callback:function ($$v) {_vm.$set(_vm.cenote, "name", $$v)},expression:"cenote.name"}}),(_vm.editName === true)?_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){_vm.editName = false}}},[_vm._v("mdi-pencil ")]):_vm._e()],1)],1):_vm._e()],1),_c('v-card-text',[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-combobox',{attrs:{"label":"Alternative Names","data-cy":"alt-names","append-icon":"","chips":"","clearable":"","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                    var attrs = ref.attrs;
                    var item = ref.item;
                    var select = ref.select;
                    var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"small":"","input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.remove(item)}}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item)+" ")])]}}]),model:{value:(_vm.cenote.alternativeNames),callback:function ($$v) {_vm.$set(_vm.cenote, "alternativeNames", $$v)},expression:"cenote.alternativeNames"}}),_c('v-select',{attrs:{"items":_vm.types,"data-cy":"cenote-type","label":"Cenote Type","rules":[function (v) { return !!v || 'Cenote Type is required'; }],"required":""},model:{value:(_vm.cenote.type),callback:function ($$v) {_vm.$set(_vm.cenote, "type", $$v)},expression:"cenote.type"}}),_c('v-checkbox',{attrs:{"data-cy":"touristic","label":"Touristic"},model:{value:(_vm.cenote.touristic),callback:function ($$v) {_vm.$set(_vm.cenote, "touristic", $$v)},expression:"cenote.touristic"}}),(_vm.dms === false)?_c('v-container',{staticClass:"d-flex d-row justify-end"},[_c('v-text-field',{attrs:{"data-cy":"latitude","label":"Latitude","rules":[
                            function (v) { return !!v || 'Cenote Latitude is required'; } ],"required":""},model:{value:(_vm.latitudeText),callback:function ($$v) {_vm.latitudeText=$$v},expression:"latitudeText"}}),_c('v-select',{staticClass:"pt-2 pl-10 pr-10",staticStyle:{"width":"5px"},attrs:{"items":_vm.latitudeDir,"dense":"","solo":"","width":"5","required":""},model:{value:(_vm.latitudeDirSelection),callback:function ($$v) {_vm.latitudeDirSelection=$$v},expression:"latitudeDirSelection"}})],1):_vm._e(),(_vm.dms === false)?_c('v-container',{staticClass:"d-flex d-row justify-center"},[_c('v-text-field',{attrs:{"data-cy":"longitude","label":"Longitude","rules":[
                            function (v) { return !!v || 'Cenote Longitude is required'; } ],"required":""},model:{value:(_vm.longitudeText),callback:function ($$v) {_vm.longitudeText=$$v},expression:"longitudeText"}}),_c('v-select',{staticClass:"pt-2 pl-10 pr-10",staticStyle:{"width":"5px"},attrs:{"items":_vm.longitudeDir,"dense":"","solo":"","width":"5","required":""},model:{value:(_vm.longitudeDirSelection),callback:function ($$v) {_vm.longitudeDirSelection=$$v},expression:"longitudeDirSelection"}})],1):_vm._e(),(_vm.dms === true)?_c('v-container',{staticClass:"d-flex d-row justify-center"},[(_vm.dms == true)?_c('v-text-field',{attrs:{"label":"Latitude","data-cy":"latitude dms","required":""},model:{value:(_vm.dmsLatitude),callback:function ($$v) {_vm.dmsLatitude=$$v},expression:"dmsLatitude"}}):_vm._e(),_c('v-select',{staticClass:"pt-2 pl-10 pr-10",staticStyle:{"width":"5px"},attrs:{"items":_vm.latitudeDir,"dense":"","solo":"","width":"5","required":""},model:{value:(_vm.latitudeDirSelection),callback:function ($$v) {_vm.latitudeDirSelection=$$v},expression:"latitudeDirSelection"}})],1):_vm._e(),(_vm.dms == true)?_c('v-container',{staticClass:"d-flex d-row justify-center"},[_c('v-text-field',{attrs:{"label":"Longitude","data-cy":"longitude dms","required":""},model:{value:(_vm.dmsLongitude),callback:function ($$v) {_vm.dmsLongitude=$$v},expression:"dmsLongitude"}}),_c('v-select',{staticClass:"pt-2 pl-10 pr-10",staticStyle:{"width":"5px"},attrs:{"items":_vm.longitudeDir,"dense":"","solo":"","width":"5","required":""},model:{value:(_vm.longitudeDirSelection),callback:function ($$v) {_vm.longitudeDirSelection=$$v},expression:"longitudeDirSelection"}})],1):_vm._e(),_c('v-container',[_c('v-select',{attrs:{"items":_vm.mode,"dense":"","solo":"","required":"","placeholder":"Change coordinate format"},on:{"input":function () {
                                if (_vm.modeSet === 'DMS') {
                                    _vm.dms = true;
                                } else if (_vm.modeSet === 'DD') {
                                    _vm.dms = false;
                                }
                            }},model:{value:(_vm.modeSet),callback:function ($$v) {_vm.modeSet=$$v},expression:"modeSet"}})],1),_c('v-select',{attrs:{"data-cy":"cenote-issues","items":_vm.issues,"label":"Cenote Issues","multiple":"","chips":""},model:{value:(_vm.cenote.issues),callback:function ($$v) {_vm.$set(_vm.cenote, "issues", $$v)},expression:"cenote.issues"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":!this.valid,"data-cy":"Save"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Save ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }